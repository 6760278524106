import cl from './indexDescription.module.scss';
import IndexDescrItem, {IAuthDescrItemProps} from "@uiComponents/IndexDescription/IndexDescrItem.tsx";

const contents: IAuthDescrItemProps[] = [
    {
        title: 'Как проходит сертификация',
        content: {
            type: 'ul',
            text: [
                '1. Проверяем проектную документацию и присваиваем класс жилья и грейд',
                '2. Очно оцениваем качество объекта, введенного в эксплуатацию',
                '3. Присваиваем категорию качества внутри класса'
            ]
        }
    }
]

const IndexDescription = () => {
    return (
        <div className={cl.container}>
            <div className={cl.title}>
                <span>Платформа для сертификации</span>
                <span>новостроек</span>
            </div>

            <div className={cl.descr}>
                <span>Здесь вы сможете добавить свои объекты и пройти сертификацию.</span>
                <span>Сотрудники помогут в процессе и ещё какой-то текст потом придумать</span>
            </div>

            <div className={cl.contentWrapper}>
                {contents.map(c => (
                    <IndexDescrItem title={c.title} content={c.content} key={c.title}/>
                ))}
            </div>
        </div>
    );
};

export default IndexDescription;